<template>
  <div class="container py-5">
    <p class="fw-bold">第十二屆全國微創醫學美容大會</p>
    <p>時間：2024/05/17-19</p>
    <p>地點：北京九華國際會議中心</p>
    <p>展位：66號</p>

    <p>
    由中國整形美容協會聯合主辦的「第十二屆全國微創醫學美容大會」將於2024年5月17-19日在北京舉行。 銘伊恆美集團受邀參會，在大會期間，銘伊恆美集團將展示其最新的醫學美容技術，與業界同行交流前沿資訊，探討學術熱點。
    <br> <br>
    醫美盛事 | 共探精彩
    <br> 
    匯集產業精英 | 瞭望產業未來
    <br> <br>
    誠摯邀請蒞臨 銘伊恆美集團【66號】
    </p>

    <img
      class="img-md rounded"
      src="@/assets/images/zh-TW/news/new_40.jpg"
    />


  </div>
</template>
<script>
export default {};
</script>
